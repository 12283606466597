import { Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { getTotalsAction } from 'reduxStore/overview/api'

import LoadingScreen from 'components/baseComponents/loading/LoadingScreen'

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
  chart: {
    style: {
      fontFamily: 'Poppins Medium',
    },
  },
})

function Totals({ period }: { period: number }) {
  const dispatch = useDispatch()
  const [chartData, setChartData] = useState<any>(null)
  const { totals } = useSelector((state: any) => state.overview)

  useEffect(() => {
    dispatch<any>(getTotalsAction(period))
  }, [period, dispatch])

  useEffect(() => {
    if (totals.isFetched && !totals.isFailed) {
      const options = {
        credits: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        title: {
          text: '',
          align: 'left',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              color: '#CCCDD1',
              style: {
                textOutline: 'none',
              },
            },
          },
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            colors: [
              '#008CFF',
              '#434348',
              '#90ed7d',
              '#EA773F',
              '#8085e9',
              '#f15c80',
              '#e4d354',
              '#2b908f',
              '#f45b5b',
              '#91e8e1',
            ],
            dataLabels: {
              enabled: true,
              format: '<b>{point.name} - {point.y:,.0f} USD',
            },
          },
        },
        series: [
          {
            name: 'Amount',
            colorByPoint: true,
            data: [
              {
                name: 'Payments',
                y: totals.payments,
              },
              {
                name: 'Conversions',
                y: totals.conversions,
              },
              {
                name: 'Lock Rates',
                y: totals.lock_rates,
              },
            ],
          },
        ],
      }

      setChartData(options)
    }
  }, [totals])

  const getTotals = () => {
    if (totals.isFetched && !totals.isFailed && chartData) {
      return <HighchartsReact highcharts={Highcharts} options={chartData} />
    }
    if (totals.isFailed) {
      return <Box m='20px'>Failed to load totals</Box>
    }
    return <LoadingScreen />
  }

  return <Box>{getTotals()}</Box>
}

export default Totals
