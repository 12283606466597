import { createSlice } from '@reduxjs/toolkit'

export const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    totals: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      conversions: 0,
      lock_rates: 0,
      payments: 0,
    },
    transactions: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      payments: {
        page_no: 0,
        total: 0,
        page_size: 0,
        records: [],
      },
      lock_rates: {
        page_no: 0,
        total: 0,
        page_size: 0,
        records: [],
      },
      conversions: {
        page_no: 0,
        total: 0,
        page_size: 0,
        records: [],
      },
    },
  },
  reducers: {
    fetchTotalsRequest: state => {
      state.totals.isFetching = true
      state.totals.isFetched = false
      state.totals.isFailed = false
    },
    fetchTotalsSuccess: (state: any, action) => {
      state.totals.isFetching = false
      state.totals.isFetched = true
      state.totals.isFailed = false
      state.totals = {
        ...state.totals,
        ...action.payload,
      }
    },
    fetchTotalsFailure: state => {
      state.totals.isFetching = false
      state.totals.isFetched = true
      state.totals.isFailed = true
    },
    fetchOverviewTransactionsRequest: state => {
      state.transactions.isFetching = true
      state.transactions.isFetched = false
      state.transactions.isFailed = false
    },
    fetchOverviewTransactionsSuccess: (state: any, action) => {
      state.transactions.isFetching = false
      state.transactions.isFetched = true
      state.transactions.isFailed = false
      state.transactions = {
        ...state.transactions,
        ...action.payload,
      }
    },
    fetchOverviewTransactionsFailure: state => {
      state.transactions.isFetching = false
      state.transactions.isFetched = true
      state.transactions.isFailed = true
    },
  },
})

export const {
  fetchTotalsRequest,
  fetchTotalsSuccess,
  fetchTotalsFailure,
  fetchOverviewTransactionsRequest,
  fetchOverviewTransactionsSuccess,
  fetchOverviewTransactionsFailure,
} = overviewSlice.actions

export default overviewSlice.reducer
